import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import Layout from '../components/Layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
    }
  ),
);

const MyCars: React.FC = () => {
  const classes = useStyles();
  return (
    <Layout>
      <div className={classes.root}>
        MyCars
    </div>
    </Layout>
  );
};

export default MyCars;